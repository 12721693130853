/*var anonymousUser = "admin";
var anonymousPwd = "password";
var systemId = "-99";
var systemSecret = "1";
var iamAnonymousURL = "http://192.168.0.210:8081/IAM2RESTService/services/v1/login";*/

microflow.factory("authenFac", [
  "$http",
  function($http) {
    this.userTempLogin = {
      username: anonymousUser,
      password: anonymousPwd,
      system_id: systemId,
      system_secret: systemSecret
    };

    this.anonymousToken = "";
    this.isLogin = false;

    this.userLogin = null;
    this.anonymousPermissionRole = [];

    this.userRoles = [];
    this.objectAccesses = [];
    this.loginURL = "";

    var authenHeader = {
      Authorization: "",
      clientId: ""
    };

    var getPermissingOfObjectAccess = function(inObjRefId, inObjType) {
      var objTemp = null;
      if (this.objectAccesses != undefined && this.objectAccesses.lenght > 0) {
        objTemp = this.objectAccesses.find(function(x) {
          var r1 =
            x.object_type.toUpperCase() === inObjType &&
            x.object_ref_id === inObjRefId;
          return r1;
        });
      }
      return objTemp;
    };

    var getUser = function() {
      return this.userLogin;
    };
    var logOut = function() {};
    var newUser = function(inUser, inPwd) {
      this.userLogin = {
        username: inUser,
        password: inPwd,
        system_id: systemId,
        system_secret: systemSecret
      };
    };

    var getAnonymousPermissionRole = function(inToken) {
      return new Promise(function(resolve, reject) {
        var req = {
          method: "GET",
          url: iamAnonymousPerm + inToken,
          headers: {
            "Content-Type": "application/json"
          }
        };

        $http(req).then(
          function(res) {
            resolve(res.data);
          },
          function(res) {
            reject(res);
          }
        );
      });
    };

    var getAnonymousUser = function() {
      return {
        username: anonymousUser,
        password: anonymousPwd,
        system_id: systemId,
        system_secret: systemSecret
      };
    };

    var anonymousLogin = function() {
      return new Promise(function(resolve, reject) {
        loginIAM(getAnonymousUser()).then(
          function(inRes) {
            authenHeader.Authorization = inRes["accessToken"];
            authenHeader.clientId = systemSecret;
            if (typeof Storage !== "undefined") {
              // Code for localStorage/sessionStorage.
              var headerStr = JSON.stringify(authenHeader);
              localStorage.setItem("header", headerStr);
              resolve(authenHeader.Authorization);
            } else {
              // Sorry! No Web Storage support..
              reject(false);
            }
          },
          function(inErr) {
            reject(false);
          }
        );
      });
    };
    var login = function(inUsername, inPwd) {
      newUser();
      var authenHeader = this.authenHeader;
      loginIAM(getUser()).then(
        function(inRes) {
          authenHeader.Authorization = inRes["Access-token"]; //old key
          authenHeader.clientId = systemSecret;
        },
        function(inErr) {
          console.error("Login fail !", inErr);
        }
      );
    };

    var loginIAM = function(inJSONData) {
      return new Promise(function(resolve, reject) {
        var req = {
          method: "POST",
          url: iamAnonymousURL,
          headers: {
            "Content-Type": "application/json"
          },
          data: inJSONData
        };

        $http(req).then(
          function(res) {
            resolve(res.data);
          },
          function(res) {
            reject(res);
          }
        );
      });
    };

    return {
      anonymousLogin: anonymousLogin,
      anonymousToken: this.anonymousToken,
      anonymousPermissionRole: this.anonymousPermissionRole,
      getAnonymousPermissionRole: getAnonymousPermissionRole,
      userRoles: this.userRoles,
      isLogin: this.isLogin,
      objectAccesses: this.objectAccesses,
      loginURL: this.loginURL,
      getPermissingOfObjectAccess: getPermissingOfObjectAccess
    };
  }
]);
